import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
    usePopupState,
    bindTrigger,
    bindMenu,
} from 'material-ui-popup-state/hooks'
import ButtonUnstyled from '@mui/base/ButtonUnstyled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
// import { faGlobe } from '@fortawesome/free-solid-svg-icons';

export const I18nBtn = () => {
    const popupState = usePopupState({ variant: 'popover', popupId: 'lngMenu' })
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const onClickWrapper = (lng) => {
        return () => {
            changeLanguage(lng)
            popupState.close()
        }
    }

    return <li className={`block px-3 rounded-2xl font-semibold text-center text-sm hover:bg-gray-100`}>
        <ButtonUnstyled
            {...bindTrigger(popupState)}
            variant="outlined"
            className="font-semibold"
        >
            <FontAwesomeIcon icon={faLanguage} className="text-2xl text-center"></FontAwesomeIcon>
        </ButtonUnstyled>
        {/* <Avatar sx={{ width: 24, height: 24 }} variant="rounded" {...bindTrigger(popupState)} className='text-blue-700'>{user?.[0]?.toUpperCase()}</Avatar> */}
        <Menu
            {...bindMenu(popupState)}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <MenuItem onClick={onClickWrapper("zh-tw")} sx={{ fontSize: "0.875rem", lineHeight: "1.125rem", fontWeight: 600 }}>繁體中文</MenuItem>
            <MenuItem onClick={onClickWrapper("en")} sx={{ fontSize: "0.875rem", lineHeight: "1.125rem", fontWeight: 600 }}>English</MenuItem>
        </Menu>
    </li>
}
