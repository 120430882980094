import { TitleWrapper, ThirdPartyAuth, RememberOrForgot } from './Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailBulk, faKey } from '@fortawesome/free-solid-svg-icons';
import './form.css'

export default function AuthForm({ height = "400px", title, subTitle, btnText, children, onSubmit }) {
    return (
        <div style={{
            height: height
        }} className="flex-col max-w-sm w-full space-y-8 rounded-xl border-gray border-2" >
            <div className="flex content-center items-center h-full">
                <div className="w-2/12"></div>
                <div className="w-8/12 py-5 h-full flex flex-col content-between">
                    <TitleWrapper className="h-3/12" title={title} subTitle={subTitle} />
                    <div className="h-3/4 flex flex-col">
                        <form className="h-full flex flex-col content-between" onSubmit={onSubmit}>
                            {/*<input type="hidden" name="remember" defaultValue="true" />*/}
                            {/* {<ThirdPartyAuth className="h-1/6 flex flex-row content-center items-center justify-center" />} */}
                            {/* <div className="h-1/6"></div> */}
                            <div className="h-5/6 mt-4">
                                {children}
                            </div>
                            <div className="h-1/6">
                                <button
                                    type="submit"
                                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    {btnText}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="w-2/12"></div>
            </div>
        </div >
    )
}