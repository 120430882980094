import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, useParams } from "react-router-dom";
import { faCalendarDays, faCircleNodes, faClipboardCheck, faPeopleCarry, faTableCells, faWeightHanging, faWifi } from "@fortawesome/free-solid-svg-icons";
import { defaultRouting } from "../../routes/default";
import { useContext } from "react";
import { OrganizationContext } from "./layout";

export default function DeviceOptions() {
    const [t, i18n] = useTranslation()
    const organizationInfo = useContext(OrganizationContext)

    return <div className="flex flex-wrap">
        <Link
            to={`/organization/${organizationInfo.organizationName}-${organizationInfo.organizationId}/management/device`}
            className="text-blue-600 font-semibold text-sm px-6 py-2 hover:bg-gray-100"
        >
            <FontAwesomeIcon icon={faWeightHanging} className="mx-2"></FontAwesomeIcon>
            {t("秤重設備")}
        </Link>
        <Link
            to={`/organization/${organizationInfo.organizationName}-${organizationInfo.organizationId}/management/gateway`}
            className="text-blue-600 font-semibold text-sm px-6 py-2 hover:bg-gray-100"
        >
            <FontAwesomeIcon icon={faWifi} className="mx-2"></FontAwesomeIcon>
            {t("閘道器")}
        </Link>
    </div>
}