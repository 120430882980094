import './App.css';
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import Login from './pages/auth/login';
import Register from './pages/auth/register';
import { useSelector } from "react-redux"
import { Suspense } from 'react';

import Logout from './pages/auth/logout';
import Index from './pages/index';
import { SideNavContent } from './components/layout/sideNavContent';
import { BasicTopNav } from './components/layout/basicTopNav';
// import { cloudRouting } from './routes/cloud';
import { defaultRouting } from './routes/default';
import { userSettingsRouting } from './routes/userSettings';
import CircularProgress from '@mui/material/CircularProgress';
import APIMIndex from './pages/organization/apim';
import UserIndex from './pages/user';
import OrganizationIndex from './pages/organization';
import UsermIndex from './pages/organization/userm';
import LocationmIndex from './pages/organization/locationm';
import DevicemIndex from './pages/organization/devicem';

function CircularIndeterminate() {
  return (
    <div className="flex justify-center items-center content-center h-screen w-screen">
      <div>
        <CircularProgress size={100} />
      </div>
    </div>
  );
}

const VerifyAuth = ({ userToken, children, routing }) => {
  return userToken ? children : <Navigate to={defaultRouting.login.path} />
}

const RedirectIfAuth = ({ userToken, children, routing }) => {
  return !userToken ? children : <Navigate to={defaultRouting.user.path} />
}

const pageMap = (user, userToken) => {
  const whiteCls = "border-b-2 shadow bg-white"
  const fixedWhiteCls = "border-b-2 shadow bg-white fixed"
  const fixedTransparentCls = "fixed"
  const appNavWithSide = BasicTopNav(user, userToken, defaultRouting, defaultRouting.home.path, defaultRouting.home.path, SideNavContent(defaultRouting), userSettingsRouting, whiteCls)
  const appNavNoSide = BasicTopNav(user, userToken, defaultRouting, defaultRouting.home.path, defaultRouting.home.path, false, userSettingsRouting, whiteCls)
  const indexNav = BasicTopNav(user, userToken, defaultRouting, defaultRouting.home.path, defaultRouting.home.path, false, userSettingsRouting, fixedTransparentCls)
  const authNav = BasicTopNav(user, userToken, defaultRouting, defaultRouting.home.path, defaultRouting.home.path, false, userSettingsRouting, fixedWhiteCls)

  return {
    [defaultRouting.home.path]: <Index nav={indexNav} startRedirection={userSettingsRouting.user.path} />,
    [defaultRouting.login.path]: <Login nav={authNav} redirect={defaultRouting.user.path} />,
    [defaultRouting.register.path]: <Register nav={authNav} redirect={userSettingsRouting.user.path} />,
    [defaultRouting.logout.path]: <Logout redirect={defaultRouting.home.path} />,
    [defaultRouting.user.path]: <UserIndex nav={appNavNoSide}></UserIndex>,
    [defaultRouting.organization.path]: <OrganizationIndex nav={appNavWithSide}></OrganizationIndex>,
    [defaultRouting.userm.path]: <UsermIndex nav={appNavWithSide}></UsermIndex>,
    [defaultRouting.locationm.path]: <LocationmIndex nav={appNavWithSide}></LocationmIndex>,
    [defaultRouting.devicem.path]: <DevicemIndex nav={appNavWithSide}></DevicemIndex>,
    [defaultRouting.apim.path]: <APIMIndex nav={appNavWithSide}></APIMIndex>,
  }
}


function routingGenerator(routing, initialToken, initialUser) {
  return Object.entries(routing).map(
    ([name, v], i) => {
      if (v.protected) return <Route
        path={v.path}
        key={name}
        element={
          <VerifyAuth userToken={initialToken} routing={routing} >
            {pageMap(initialUser, initialToken)[v.path]}
          </VerifyAuth>
        }
        exact={v.exact} />
      if (v.noAuthOnly) return <Route
        path={v.path}
        key={name}
        element={
          <RedirectIfAuth userToken={initialToken} routing={routing} >
            {pageMap(initialUser, initialToken)[v.path]}
          </RedirectIfAuth>
        }
        exact={v.exact} />
      return <Route
        key={name}
        path={v.path}
        element={pageMap(initialUser, initialToken)[v.path]}
        exact={v.exact} />
    }
  )
}

function App() {
  const initialToken = useSelector(state => state.token)
  const initialUser = useSelector(state => state.user)

  return (
    <Suspense fallback={<CircularIndeterminate></CircularIndeterminate>}>
      <BrowserRouter>
        <Routes>
          {routingGenerator(defaultRouting, initialToken, initialUser)}
          {/* {routingGenerator(cloudRouting, initialToken, initialUser)} */}
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
