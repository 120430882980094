import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createContext, useEffect } from "react";
import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
// import DetailOptions from "../../components/organization/detailOptions";
import WithNav from "../layout/withNav";
import jwtDecode from "jwt-decode";
import { getUser, getUserOwnRole } from "../../api/user/user";

export const OrganizationContext = createContext({
    userId: 0,
    role: "reader",
    organizationId: 0,
    organizationIdentity: "",
    organizationName: "",
    setOrganizationContext: () => { },
})

export default function OrganizationLayout({ nav, subNav, side, directory, children, overflowX }) {
    const params = useParams()
    const [t, i18n] = useTranslation()
    const [role, setRole] = useState("reader")
    const [organizationContext, setOrganizationContext] = useState({
        role: "reader",
        organizationIdentity: params.rganizationId,
        organizationId: 0,
        organizationName: "",
        agreement: "user",
        userId: 0,
    })
    const organizationIdentity = params?.organizationId
    const organizationIdArray = params?.organizationId?.split("-")
    const organizationId = parseInt(organizationIdArray[organizationIdArray.length - 1])
    const organizationName = organizationIdArray.slice(0, organizationIdArray.length - 1).join("-")
    const jwt = jwtDecode(localStorage.getItem("token"))
    const agreement = jwt.agreement
    const userId = jwt.uid

    const getUserRole = async (userId, organizationId) => {
        const res = await getUserOwnRole(userId, organizationId)
        setRole(res?.data?.organizationRole?.name || "reader")
    }

    useEffect(() => {
        getUserRole(userId, organizationId)
    }, [userId, organizationId])

    useEffect(() => {
        setOrganizationContext({
            ...organizationContext,
            organizationIdentity,
            organizationId,
            organizationName,
            agreement,
            userId,
            role
        })
    }, [organizationId, organizationName, agreement, userId, role])

    return <WithNav nav={nav}>
        <OrganizationContext.Provider value={organizationContext} >
            <div className="border-b-2">
                {subNav}
            </div>
            <div className="flex">
                {side &&
                    <div className="h-full border-r-2 min-h-screen w-60">
                        {side}
                    </div>
                }
                <div className={`pl-10 pt-4 ${side ? "w-[calc(100%-15rem)]" : "w-full"} min-h-[calc(100vh-80px)]`}>
                    <div className="mx-2">
                        <span className="text-sm text-gray-600">
                            <FontAwesomeIcon icon={faPeopleGroup}></FontAwesomeIcon>
                        </span>
                        <span className="text-sm mx-2 text-gray-600">
                            {t("Organization")}
                        </span>
                        <span className="text-sm text-gray-600">
                            -
                        </span>
                        <span className="text-sm mx-2 text-gray-600">
                            {organizationName}
                        </span>
                    </div>
                    <div className="mt-3 mx-2">
                        <span className="text-xl font-bold">
                            {t(`${directory}`)}
                        </span>
                    </div>
                    <div className={`mt-4 ${overflowX ? "overflow-x-auto" : "overflow-x-hidden"} h-fit`}>
                        {children}
                    </div>
                </div>
            </div>
        </OrganizationContext.Provider>
    </WithNav>
}