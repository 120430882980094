import { Link } from "react-router-dom";
import "./index.css"
import { useTranslation } from "react-i18next";

export default function Index({ nav, startRedirection = "/" }) {
    const [t, i18n] = useTranslation()

    return (
        <div className="fade-out">
            <div className="h-screen bg-landing">
                {nav}
                <div className="h-1/5">
                </div>
                <div className="h-3/5 ">
                    <div>
                        <h1 className="text-5xl font-extrabold text-center">
                            {t('讓您的生產流程變得更加智慧')}
                            <br></br>
                            {t('讓您的生產管理與監控更加容易')}
                        </h1>
                        <h2 className="text-center text-xl mt-4 font-semibold">
                            {t("一個讓您能管理智慧農場設備的管理平台")}
                            <br></br>
                            {t('一個讓您能自行擴展的資料服務')}
                            {/* <br></br>
                                A Easy Way to Manage your Projects and Courses. */}
                        </h2>
                    </div>
                    <div className="flex item-center justify-center mt-4">
                        <div>
                            <Link
                                to={startRedirection}
                                className="text-xl font-semibold px-8 py-2 bg-primary text-white rounded-2xl"
                            >
                                {t('開始使用')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}