import { useState, useRef, useEffect, Fragment } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const FormDialog = ({ title, content, forms, closeText, submitText, deleteText, children, onClick, onSubmit, onDelete, className, style, maxWidth }) => {
    const [open, setOpen] = useState(false);
    const [scroll, setScroll] = useState('paper');

    const handleClickOpen = (scrollType) => () => {
        onClick?.()
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleDelete = () => {
        handleClose()
        onDelete()
    }

    const handleSubmit = () => {
        handleClose()
        onSubmit()
    }

    const descriptionElementRef = useRef(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return <Fragment>
        <button onClick={handleClickOpen('paper')} className={className} style={style}>{children}</button>
        {/* <Button onClick={handleClickOpen('body')}>scroll=body</Button> */}
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={maxWidth || "sm"}
            // scroll={scroll}
            fullWidth={maxWidth ? true : false}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            className="overflow-auto"
        >
            <DialogTitle id="scroll-dialog-title">
                <span className="text-2xl font-semibold">
                    {title}
                </span>
            </DialogTitle>
            <DialogContent
                dividers={scroll === 'paper'}
            >
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                    className="break-words min-w-[30rem]"
                >
                    {content}
                </DialogContentText>
                {forms}
            </DialogContent>
            <DialogActions>
                <div className="flex w-full justify-between">
                    <div>
                        {deleteText && onDelete ? <button
                            className="bg-red-500 hover:bg-red-700 text-white px-4 mx-2 py-1 rounded-xl"
                            onClick={handleDelete}
                        >
                            {deleteText}
                        </button> : ""}
                    </div>
                    <div>
                        <button
                            onClick={handleClose}
                            className="text-gray-600 font-semibold px-4 py-1 mx-2 hover:bg-gray-100 border rounded-xl"
                        >
                            {closeText}
                        </button>
                        {submitText && onSubmit ?
                            <button
                                onClick={handleSubmit}
                                className="text-blue-600 font-semibold px-4 py-1 mx-2 hover:bg-gray-100 border rounded-xl"
                            >
                                {submitText}
                            </button> :
                            ""
                        }
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    </Fragment>
}

export default FormDialog;