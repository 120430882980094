import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import Collapse from '@mui/material/Collapse';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import ExpandLess from '@mui/icons-material/ExpandLess';
// import ExpandMore from '@mui/icons-material/ExpandMore';
// import StarBorder from '@mui/icons-material/StarBorder';
// import DraftsIcon from '@mui/icons-material/Drafts';
// import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faList, faMicrochip, faPeopleCarry, faWeightHanging } from '@fortawesome/free-solid-svg-icons';
import { Typography } from '@mui/material';

export default function NavList() {
    const [t, i18n] = useTranslation()
    const [open, setOpen] = React.useState(true);

    const params = useParams()
    const organizationIdentity = params.organizationId

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            <Link to={`/organization/${organizationIdentity}/management/user`}>
                <ListItemButton>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faList}></FontAwesomeIcon>
                    </ListItemIcon>
                    <ListItemText primary={<Typography style={{ fontSize: "0.875rem", lineHeight: "1.125rem", fontWeight: 600 }}>{t("使用者管理")}</Typography>} />
                </ListItemButton>
            </Link>
            {/* <Link to={`/organization/${organizationIdentity}/management/location`}>
                <ListItemButton>
                    <ListItemIcon>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faPeopleCarry}></FontAwesomeIcon>
                        </ListItemIcon>
                    </ListItemIcon>
                    <ListItemText primary={<Typography style={{ fontSize: "0.875rem", lineHeight: "1.125rem", fontWeight: 600 }}>{t("地點管理")}</Typography>} />
                </ListItemButton>
            </Link> */}
            <Link to={`/organization/${organizationIdentity}/management/device`}>
                <ListItemButton>
                    <ListItemIcon>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faMicrochip}></FontAwesomeIcon>
                        </ListItemIcon>
                    </ListItemIcon>
                    <ListItemText primary={<Typography style={{ fontSize: "0.875rem", lineHeight: "1.125rem", fontWeight: 600 }}>{t("設備管理")}</Typography>} />
                </ListItemButton>
            </Link>
            <Link to={`/organization/${organizationIdentity}/management/api`}>
                <ListItemButton>
                    <ListItemIcon>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faKey}></FontAwesomeIcon>
                        </ListItemIcon>
                    </ListItemIcon>
                    <ListItemText primary={<Typography style={{ fontSize: "0.875rem", lineHeight: "1.125rem", fontWeight: 600 }}>{t("API管理")}</Typography>} />
                </ListItemButton>
            </Link>
        </List>
    );
}