import { useTranslation } from "react-i18next";
import PopupState from "material-ui-popup-state";
import { Avatar, Menu, MenuItem } from "@mui/material";
import {
    usePopupState,
    bindTrigger,
    bindMenu,
} from 'material-ui-popup-state/hooks'
import { Link } from "react-router-dom";

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: "#036ECB",
            width: 24,
            height: 24,
        },
        children: `${name?.split(' ')?.[0]?.[0]}`,
    };
}

export const UserAvatar = ({ user, userSettings }) => {
    const { t, i18n } = useTranslation();
    const popupState = usePopupState({ variant: 'popover', popupId: 'userSettingsMenu' })

    return <li className={`block px-3 py-2 rounded-2xl font-semibold hover:bg-gray-100`}>
        <Avatar
            {...bindTrigger(popupState)}
            {...stringAvatar(user)}
            variant="rounded"
            className='text-blue-700' >
            {/* {user?.[0]?.toUpperCase()} */}
        </Avatar>
        <Menu
            {...bindMenu(popupState)}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            {userSettings && Object.entries(userSettings).map(([name, v], i) => {
                if (user && v.noAuthOnly) return
                if (!user && v.protected) return
                if (v.nav) return <MenuItem onClick={popupState.close} key={i} sx={{ fontSize: "0.875rem", lineHeight: "1.125rem", fontWeight: 600 }}>
                    <Link to={v.path}>{t(v.nav)}</Link>
                </MenuItem>
            })}
        </Menu>
    </li>
}