import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Select, MenuItem, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState, useEffect, Fragment, useContext, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faArrowAltCircleRight, faAdd, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import FormDialog from "../../components/basic/formDialog";
import WithNav from "../layout/withNav";
import { Link, useParams } from "react-router-dom";
import { getUserOrgs } from "../../api/user/user";
import { DataObjectSharp } from "@mui/icons-material";
import jwtDecode from "jwt-decode";
import OrganizationLayout, { OrganizationContext } from "./layout";
import { getOrgUsers } from "../../api/user/org";
import { getAllDevice, getDeviceData, getLatestWeighingData } from "../../api/device/device";
import moment from "moment"
import Plot from "react-plotly.js";
import { LocalizationProvider } from "@mui/x-date-pickers"
import { DateTimePicker, DatePicker } from "@mui/x-date-pickers"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const PairingForm = ({children}) => {
    
}

const DataForm = ({organizationId, deviceId, deviceCode}) => {
    const [t, i18n] = useTranslation()
    const [st, setSt] = useState(moment().set({hour:0,minute:0,second:0,millisecond:0}).toISOString())
    const [et, setEt] = useState(moment().add(1, 'days').set({hour:0,minute:0,second:0,millisecond:0}).toISOString())
    const [plotData, setPlotData] = useState({
        x: [],
        y: [],
        name: "",
        type: "scatter",
        mode: "markers",
    })

    const makePlotData = (x, y, identity) => {
        return {
            x: x,
            y: y,
            name: identity,
            type: "scatter",
            mode: "markers",
        }
    }

    useEffect(()=>{
        const job = async(organizationId, deviceId, deviceCode) => {
            const x = [new Date(st), new Date(et)]
            const y = [NaN, NaN]
            const data = await getDeviceData(organizationId, deviceId, st, et)

            if (!data.data) return

            data.data.forEach(v => {
                x.push(new Date(v.parsedGatewayTimestamp))
                y.push(v.parsedWeight)
            })

            setPlotData(makePlotData(x, y, deviceCode))
        }

        organizationId && deviceId && job(organizationId, deviceId, deviceCode)
    },[st, et, organizationId, deviceId, deviceCode])

    return (
        <Fragment>
            <div className="mt-4 ml-8">                
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="開始時間"
                        value={st}
                        onChange={(newValue) => {
                            setSt(newValue.toISOString());
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <DatePicker
                        label="結束時間"
                        value={et}
                        onChange={(newValue) => {
                            setEt(newValue.toISOString());
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </div>
            <div className="mb-2">
                <Plot                    
                    data={[
                       plotData
                    ]}
                    useResizeHandler={true}
                    style={{ width: "100%", height: "100%" }}
                />
            </div>
        </Fragment>
        )
}

const OrganizationForm = ({ form = {}, setForm }) => {
    const [t, i18n] = useTranslation()

    return (
        <Fragment>
            <div className="mb-2">
                <span >
                    {t("在下方填入組織資訊，以建立組織。")}
                </span>
            </div>
            <TextField
                margin="dense"
                id="code"
                label={t("組織名稱")}
                type="text"
                fullWidth
                variant="outlined"
                name="name"
                onChange={(e) => { setForm({ ...form, [e.target.name]: e.target.value }) }}
            />
            <TextField
                margin="dense"
                id="code"
                label={t("邀請碼")}
                type="text"
                fullWidth
                variant="outlined"
                name="invitationCode"
                onChange={(e) => { setForm({ ...form, [e.target.name]: e.target.value }) }}
            />
            <TextField
                margin="dense"
                id="code"
                label={t("描述")}
                type="text"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                name="description"
                onChange={(e) => { setForm({ ...form, [e.target.name]: e.target.value }) }}
            />
        </Fragment>
    )
}


const GatewayTable = ({gatewayData, organizationInfo}) => {
    const [t, i18n] = useTranslation()

    const statusColorMap = {
        "Fatal": "bg-red-500",
        "Fatel": "bg-red-500",
        "fatal": "bg-red-500",
        "fatel": "bg-red-500",
        "offline": "bg-gray-300",
        "health": "bg-green-500",
        "warning": "bg-yellow-500",
    }

    const healthyCheck = (status, t) => {
        console.log(status)
        if (t === "0001-01-01T00:00:00Z") return "offline"
        let mt = new Date(t)
        let ct = new Date()

        let msDiff = ct.getTime() - mt.getTime()
        let sDiff = msDiff / 1000
        if (sDiff < 60 && t !== "0001-01-01T00:00:00Z" ) return "health"

        if (sDiff > 3600) return "offline"
        if (status) return status

        return "health"
    }

    return (
        <Table
                sx={{ minWidth: 280 }}
                aria-label="simple table" >
                <TableHead>
                    <TableRow>
                        <TableCell>{t("設備代碼")}</TableCell>
                        <TableCell>{t("設備名")}</TableCell>
                        <TableCell>{t("下層設備")}</TableCell>
                        {organizationInfo.role === "admin" ? <TableCell>{t("修改配對")}</TableCell> : <></>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {gatewayData?.map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.deviceCode &&
                                    <div className="flex items-center">
                                        {row.deviceCode}
                                    </div>
                                }
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.deviceName}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.children.length > 0 ? row.children.map((child, index)=>{
                                    return <button 
                                            className="rounded-lg px-2 mx-1 my-1 py-1 text-white bg-cyan-500 hover:bg-cyan-700"
                                            key={index}
                                        >
                                            {child.deviceCode}
                                        </button>
                                }) : <></>}
                            </TableCell>
                            {organizationInfo.role === "admin" ? <TableCell component="th" scope="row">                                
                                <FormDialog
                                    title={t("修改配對")}
                                    forms={<DataForm organizationId={organizationInfo.organizationId} deviceId={row.dId} deviceCode={row.deviceCode}></DataForm>}
                                    closeText={t("返回")}
                                    className="rounded-lg px-2 py-1 text-white bg-cyan-500 hover:bg-cyan-700"
                                    maxWidth={"lg"}
                                >
                                    {t("修改配對")}
                                </FormDialog>
                            </TableCell> : <></>}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
    )
}

const DeviceTable = ({weighingData, organizationInfo}) => {
    const [t, i18n] = useTranslation()

    const statusColorMap = {
        "Fatal": "bg-red-500",
        "Fatel": "bg-red-500",
        "fatal": "bg-red-500",
        "fatel": "bg-red-500",
        "offline": "bg-gray-300",
        "health": "bg-green-500",
        "warning": "bg-yellow-500",
    }

    const healthyCheck = (status, t) => {
        console.log(status)
        if (t === "0001-01-01T00:00:00Z") return "offline"
        let mt = new Date(t)
        let ct = new Date()

        let msDiff = ct.getTime() - mt.getTime()
        let sDiff = msDiff / 1000
        if (sDiff < 60 && t !== "0001-01-01T00:00:00Z" ) return "health"

        if (sDiff > 3600) return "offline"
        if (status) return status

        return "health"
    }

    return (
        <Table
                sx={{ minWidth: 280 }}
                aria-label="simple table" >
                <TableHead>
                    <TableRow>
                        <TableCell>{t("設備代碼")}</TableCell>
                        <TableCell>{t("設備名")}</TableCell>
                        <TableCell>{t("所屬閘道器")}</TableCell>
                        <TableCell>{t("RF")}</TableCell>
                        <TableCell>{t("重量(g)")}</TableCell>
                        <TableCell>{t("電壓(V)")}</TableCell>
                        <TableCell>{t("CPU溫度(℃)")}</TableCell>
                        <TableCell>{t("資料時間")}</TableCell>
                        <TableCell>{t("檢視資料")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {weighingData?.map((row) => (
                        <TableRow
                            key={row.dId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.deviceCode &&
                                    <div className="flex items-center">
                                        <Tooltip
                                            title={healthyCheck(row.deviceStatus, row.gatewayTimestamp)}
                                        >
                                            <button className={`w-3 h-3 rounded-full mr-2 ${statusColorMap[healthyCheck(row.deviceStatus, row.gatewayTimestamp)]}`}>
                                            </button>
                                        </Tooltip>
                                        {row.deviceCode}
                                    </div>
                                }
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.displayName}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.parentDeviceCode && <button className="rounded-lg px-2 py-1 text-white bg-cyan-500 hover:bg-cyan-700 ">{row.parentDeviceCode}</button>}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.rfch}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.parsedWeight}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.bat/50}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.ptemp}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <div>
                                    <div>
                                        {row.parsedDeviceTimestamp !== "0001-01-01T00:00:00Z" ? `${new Date(row.parsedDeviceTimestamp).toLocaleString()} (機上)` : <></>}
                                    </div>
                                    <div className="mt-1">
                                        {row.receivedAt !== "0001-01-01T00:00:00Z" ? `${new Date(row.gatewayTimestamp).toLocaleString()} (閘道器)` : <></>}
                                    </div>
                                    <div className="mt-1">
                                        {row.receivedAt !== "0001-01-01T00:00:00Z" ? `${new Date(row.receivedAt).toLocaleString()} (雲端)` : <></>}
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell component="th" scope="row">                                
                                <FormDialog
                                    title={t("設備資料")}
                                    forms={<DataForm organizationId={organizationInfo.organizationId} deviceId={row.dId} deviceCode={row.deviceCode}></DataForm>}
                                    closeText={t("返回")}
                                    className="rounded-lg px-2 py-1 text-white bg-cyan-500 hover:bg-cyan-700"
                                    maxWidth={"lg"}
                                >
                                    {t("檢視")}
                                </FormDialog>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
    )
}

export default function DeviceContent() {
    const [weighingData, setWeighingData] = useState([])
    const [gatewayData, setGatewayData] = useState([])
    const [t, i18n] = useTranslation()
    const organizationInfo = useContext(OrganizationContext)
    const deviceType = useParams().deviceType

    console.log(deviceType)

    const getLatestData = async (organizationId) => {
        const resp = await getLatestWeighingData(organizationId, 1)
        // console.log(resp.data)

        setWeighingData(resp.data || [])
    }

    const getGatewayData = async (organizationId) => {
        const resp = await getAllDevice(organizationId, "E")
        // console.log(resp.data)

        setGatewayData(resp.data || [])
    }

    useEffect(() => {
        organizationInfo.organizationId && getLatestData(organizationInfo.organizationId)
        organizationInfo.organizationId && getGatewayData(organizationInfo.organizationId)
    }, [organizationInfo.organizationId])

    return <div className="pr-10 m-2">
        <div className="mb-6">
            <Select
                labelId="locationFilter-select-label"
                id="locationFilter-select"
                label="locationFilter"
                name="locationFilter"
                size="small"
                className="mr-2"
                defaultValue={"all"}
                // onChange={(e) => { setForm({ ...form, [e.target.name]: e.target.value }) }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    }
                }}
            >
                <MenuItem
                    value={"all"}
                >
                    {t("所有地點")}
                </MenuItem>
            </Select>
        </div>
        <TableContainer component={Paper}>
            {deviceType === "device" 
                ? <DeviceTable
                    weighingData={weighingData}
                    organizationInfo={organizationInfo}
                /> 
                : <GatewayTable
                    gatewayData={gatewayData}
                    organizationInfo={organizationInfo}
                />
            }
        </TableContainer >
    </div>
}
