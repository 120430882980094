import axios from "axios";

let url = "https://api.sq.tornadoedge.app/user"
// let url = "http://localhost:8003"

// switch (process.env.NODE_ENV) {
//   case 'production':
//     url = '';
//     break;
//   case 'development':
//   default:
//     url = 'http://localhost:8080';
// }

// baseURL是你API的主要Domain，只後發請求時只要填相對路徑就可以了
const instance = axios.create({
  baseURL: url,
  headers: { 'Content-Type': 'application/json' },
  timeout: 20000,
  mode: 'cors',
  cache: 'no-cache',
  credentials: 'same-origin',
  redirect: 'follow',
  referrerPolicy: 'no-referrer',
});

instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token")

    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
)

instance.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          console.log("Unauthorized")
          localStorage.removeItem("token")
          break
        case 404:
          console.log("你要找的頁面不存在")
          // go to 404 page
          break
        case 500:
          console.log("程式發生問題")
          // go to 500 page
          break
        default:
          console.log(error.message)
      }
    }
    if (!window.navigator.onLine) {
      alert("網路出了點問題，請重新連線後重整網頁");
      return;
    }
    return Promise.reject(error);
  }
);

export default function interceptors(method, url, data = null, config) {
  method = method.toLowerCase();
  switch (method) {
    case "post":
      return instance.post(url, data, config);
    case "get":
      return instance.get(url, { params: data }, config);
    case "delete":
      return instance.delete(url, { params: data }, config);
    case "put":
      return instance.put(url, data);
    case "patch":
      return instance.patch(url, data);
    default:
      console.log(`未知的 method: ${method}`);
      return false;
  }
}