export const defaultRouting = {
    home: { path: "/", exact: true, noAuthOnly: true },
    login: { path: "/login", exact: true, nav: "登入", protected: false, noAuthOnly: true },
    logout: { path: "/logout", exact: true, protected: true },
    register: { path: "/register", exact: true, nav: "註冊", protected: false, noAuthOnly: true },
    user: { path: "/user", exact: true, protected: true },
    organization: { path: "/organization/:organizationId", exact: true, protected: true },
    userm: { path: "/organization/:organizationId/management/user", exact: true, protected: true, side: "使用者管理" },
    locationm: { path: "/organization/:organizationId/management/location", exact: true, protected: true, side: "地點管理" },
    orgm: { path: "/organization/:organizationId/management", exact: true, protected: true },
    devicem: { path: "/organization/:organizationId/management/:deviceType", exact: true, protected: true, side: "設備管理" },
    apim: { path: "/organization/:organizationId/management/api", exact: true, protected: true, side: "API管理" },
}