import req from "./api";

export const getOrgCredentials = (organizationId) => {
    return req("get", `/api/v1/apim/organization/${organizationId}/credential`)
}

export const createOrgCredential = (organizationId, displayName) => {
    return req("post", `/api/v1/apim/organization/${organizationId}/credential`, {displayName})
}

export const updateOrgCredential = (organizationId, credId, displayName) => {
    return req("put", `/api/v1/apim/organization/${organizationId}/credential/${credId}`, {displayName})
}

export const deleteOrgCredential = (organizationId, credId) => {
    return req("delete", `/api/v1/apim/organization/${organizationId}/credential/${credId}`)
}