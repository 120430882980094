import "./sideNav.css";
import { useState, useRef } from "react"
import { useOutside } from "../hooks/useOutside";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import NavList from "./navList";

export default function SideNav({ widthClass = "", className = "", children }) {
    const [isActive, setIsActive] = useState(false)
    const ref = useRef(null)

    const outsideHandler = (ref, event) => {
        try {
            if (event.target?.id === "sideNavBack") {
                setIsActive(false)
            }
        } catch {

        }
    }

    useOutside(ref, outsideHandler)

    return (
        <>
            <button className="w-8" onClick={() => { setIsActive(old => !old) }}>
                <span className="text-gray-700 text-xl rounded-lg">
                    <FontAwesomeIcon icon={faBars} />
                </span>
            </button>
            <nav className={`side-nav ${widthClass} ${className} ${isActive ? "fixed" : "hidden"}`} ref={ref}>
                <div className="h-10 shadow border flex justify-start px-8 items-center content-center">
                    <button onClick={() => setIsActive(false)}>
                        <FontAwesomeIcon icon={faWindowClose} className="text-2xl">
                        </FontAwesomeIcon>
                    </button>
                    {/* <span className="mx-8 font-semibold">
                        DLAP
                    </span> */}
                </div>
                {/* <ul className="nav nav-pill flex-col">
                    {children}
                </ul> */}
                <div>
                    <NavList></NavList>
                </div>
            </nav>
            <div className={`side-nav-back ${isActive ? "fixed" : "hidden"}`} id="sideNavBack"></div>
        </>
    )
}