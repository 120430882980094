import { TitleWrapper, ThirdPartyAuth, RememberOrForgot } from '../../components/auth/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailBulk, faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import AuthForm from "../../components/auth/form";
import TopNav from "../../components/layout/topNav";
import { useDispatch } from "react-redux"
import { SET_TOKEN, SET_USER } from "../../store"
import { userSignUp } from '../../api/auth/auth';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import WithNav from '../layout/withNav';
import { useTranslation } from 'react-i18next';
import { AlertTitle, Alert } from '@mui/material';

export default function Register({ nav, redirect = "/" }) {
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    const [t, i18n] = useTranslation()
    const [errorMessage, setErrorMessage] = useState("")

    const [registrationPayload, setRegistrationPayload] = useState({
        username: "",
        password: "",
    });

    function changeRegistrationPayload(event) {
        setRegistrationPayload(currentRegistrationPayload => ({ ...currentRegistrationPayload, [event.target.name]: event.target.value }))
    }

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const resp = await userSignUp(
                registrationPayload
            )

            // dispatch({
            //     type: SET_TOKEN,
            //     payload: resp.data.token
            // })

            // dispatch({
            //     type: SET_USER,
            //     payload: resp.data.user
            // })

            // console.log(resp)

            if (searchParams?.get("continue")) {
                window.location.href = searchParams.get("continue")
                return
            }
            navigate(redirect)
        } catch (error) {
            let isDuplicate = false
            let field = ""
            if (error.response?.data?.error?.includes("duplicate")) isDuplicate = true
            if (error.response?.data?.error?.includes("users_username")) field = "username"
            if (error.response?.data?.error?.includes("users_email")) field = "email"

            console.log(field, isDuplicate)
            if (isDuplicate) setErrorMessage(`${field} duplicate`)
        }
    }

    return (
        <WithNav className={"h-screen"} nav={nav}>
            <div className="flex h-full w-full justify-center content-center items-center mx-auto fade-out">
                <AuthForm
                    onSubmit={handleSubmit}
                    title={t("註冊")}
                    subTitle={t("讓我們建立更智慧的生活")}
                    btnText={t("註冊")}
                    height="480px"
                >
                    <div className="flex content-center items-center">
                        <FontAwesomeIcon style={{ color: "#3B5998" }} className="" icon={faUser} color="" />
                        <label htmlFor="username" className="sr-only">
                            {t("帳號")}
                        </label>
                        <input
                            id="username"
                            name="username"
                            autoComplete="username"
                            required
                            className="relative block w-full px-3 py-2 focus:z-10 sm:text-sm"
                            placeholder={t("帳號")}
                            onChange={changeRegistrationPayload}
                        />
                    </div>
                    <div className="flex content-center items-center">
                        <FontAwesomeIcon style={{ color: "#3B5998" }} className="" icon={faMailBulk} color="" />
                        <label htmlFor="email-address" className="sr-only">
                            信箱 address
                        </label>
                        <input
                            id="email-address"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className="relative block w-full px-3 py-2 focus:z-10 sm:text-sm"
                            placeholder={t("信箱")}
                            onChange={changeRegistrationPayload}
                        />
                    </div>
                    <div className="flex content-center items-center">
                        <FontAwesomeIcon style={{ color: "#3B5998" }} className="" icon={faKey} color="" />
                        <label htmlFor="password" className="sr-only">
                            {t("密碼")}
                        </label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            className="relative block w-full px-3 py-2 focus:z-10 sm:text-sm"
                            placeholder={t(`密碼`)}
                            onChange={changeRegistrationPayload}
                        />
                    </div>
                    <div className="flex content-center items-center">
                        <FontAwesomeIcon style={{ color: "#3B5998" }} className="" icon={faKey} color="" />
                        <label htmlFor="confirmed-password" className="sr-only">
                            確認密碼
                        </label>
                        <input
                            id="confirmed-password"
                            name="confirmedPassword"
                            type="password"
                            required
                            className="relative block w-full px-3 py-2 focus:z-10 sm:text-sm"
                            placeholder={t(`確認密碼`)}
                            onChange={changeRegistrationPayload}
                        />
                    </div>
                    <div className='py-4'>
                        {errorMessage.length > 0 ? <Alert severity="error">{t(errorMessage)}</Alert> : ""}
                    </div>
                </AuthForm>
            </div>
        </WithNav>
    )
}