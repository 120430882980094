import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faGooglePlus } from '@fortawesome/free-brands-svg-icons';
import './auth.css';

export function TitleWrapper(props) {
    return (
        <div className={props.className}>
            <h2 className="m-2 text-center text-3xl font-extrabold text-gray-700 ">{props.title}</h2>
            <hr className="m-2 bg-gray-400  h-0.5"></hr>
            <p className="m-2 text-center text-lg font-bold text-gray-700">
                {props.subTitle}
            </p>
        </div>
    )
}

export function ThirdPartyAuth(props) {
    return (
        <div className={props.className}>
            <button
                type="button"
                className="mx-2"
            >
                <FontAwesomeIcon style={{ color: "#3B5998" }} className="fa-2x" icon={faFacebookSquare} color="" />
            </button>
            <button
                type="button"
                className="mx-2"
            >
                <FontAwesomeIcon style={{ color: "#DD4B39" }} className="fa-2x" icon={faGooglePlus} />
            </button>
        </div>
    )
}

export function RememberOrForgot(props) {
    return (
        <div className="mt-4 flex items-center flex-row justify-between">
            <div className="flex items-center">
                <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4  focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm ">
                    Remember me
                </label>
            </div>

            <div className="text-sm">
                <a href="/findback" className="font-medium  ">
                    Forgot your password?
                </a>
            </div>
        </div>
    )
}