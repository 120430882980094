import req from "./api";

export const userSignUp = (signUpData) => {
  return req("post", "/api/v1/auth/register", signUpData)
}

export const userLogIn = (logInData) => {
  return req("post", "/api/v1/auth/login", logInData)
}

export const tokenVerification = (token) => {
  return req("get", "/api/v1/auth/token/verification", {}, {
    headers: { 'Authorization': `Bearer ${token}` },
  })
}

export const userTokenChecker = async (token, logoutDispatcher) => {
  try {
    let resp = await tokenVerification(token)

    return true
  } catch (error) {
    switch (error.response.status) {
      case 401:
        if (!token) return false

        logoutDispatcher()
        console.log("dispatching")
        return false
    }
  }
}