import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

export default function NavItem({ to, children }) {
    const { t, i18n } = useTranslation();
    return (
        <li>
            <Link
                to={to}
                className={`block px-3 rounded-2xl font-semibold text-sm hover:bg-gray-100`}
            >
                <span>
                    {t(children)}
                </span>
            </Link>
        </li>
    )
}
