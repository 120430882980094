import req from "./api";

export const getOrgUsers = (organizationId) => {
    return req("get", `/api/v1/organization/${organizationId}/user`)
}

export const getOrg = (organizationId) => {
    return req("get", `/api/v1/organization/${organizationId}`)
}

export const updateOrg = (organizationId, data) => {
    return req("put", `/api/v1/organization/${organizationId}`, data)
}

export const updateOrgInvitationCode = (organizationId) => {
    return req("put", `/api/v1/organization/${organizationId}/invitation-code`)
}

export const deleteOrgUser = (organizationId, userId) => {
    return req("delete", `/api/v1/organization/${organizationId}/user/${userId}`)
}

export const getAllOrgRoles = () => {
    return req("get", `/api/v1/organizationRole`)
}

export const updateUserRole = (organizationId, userId, orgRoleId) => {
    return req("put", `/api/v1/organization/${organizationId}/user/${userId}/role`, {roleId: orgRoleId})
}

// router.Put("/organization/:organizationId", MakeUpdateOrgHandler(service))
// router.Put("/organization/:organizationId/invitation-code", MakeUpdateInvitationCodeHandler(service))
// router.Get("/organization/:organizationId/user", MakeGetOrgUsers(service))

// router.Delete("/organization/:organizationId/user/:userId", MakeDeleteOrgUser(service))