import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import FormDialog from "../../components/basic/formDialog";
import WithNav from "../layout/withNav";
import OrganizationLayout, { OrganizationContext } from "./layout";
import { getOrgCredentials } from "../../api/apim/apim";
import { useContext } from "react";
import DeviceOptions from "./deviceOptions";
import DeviceContent from "./device";



export default function DevicemIndex({ nav }) {
    return (
        <OrganizationLayout nav={nav} subNav={<DeviceOptions></DeviceOptions>} directory={"您的組織中的設備"}>
            <DeviceContent></DeviceContent>
        </OrganizationLayout >
    )
}