import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState, useEffect, Fragment, useContext, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faArrowAltCircleRight, faAdd, faCircleInfo, faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import FormDialog from "../../components/basic/formDialog";
import WithNav from "../layout/withNav";
import { Link, useParams } from "react-router-dom";
import { getUserOrgs } from "../../api/user/user";
import { DataObjectSharp } from "@mui/icons-material";
import jwtDecode from "jwt-decode";
import OrganizationLayout, { OrganizationContext } from "./layout";
import { deleteOrgUser, getAllOrgRoles, getOrg, getOrgUsers, updateOrg, updateUserRole } from "../../api/user/org";
import { updateOrgInvitationCode } from "../../api/user/org";

const OrganizationForm = ({orgId, form = {}, setForm }) => {
    const [t, i18n] = useTranslation()

    const handleUpdateCode = async () => {
        const res = await updateOrgInvitationCode(orgId)
        // console.log(res.data)
        res?.data?.invitationCode && setForm({...form, invitationCode: res.data.invitationCode})
    }

    return (
        <Fragment>
            <div className="mb-2">
                <span >
                    {t("在下方填入組織資訊，以建立組織。")}
                </span>
            </div>
            <TextField
                margin="dense"
                id="code"
                label={t("組織名稱")}
                type="text"
                fullWidth
                variant="outlined"
                name="name"
                defaultValue={form.name}
                onChange={(e) => { setForm({ ...form, [e.target.name]: e.target.value }) }}
            />
            <div className="flex items-center align-middle">
                <div>
                    <TextField
                        margin="dense"
                        id="code"
                        label={t("邀請碼")}
                        type="text"
                        variant="outlined"
                        name="invitationCode"
                        disabled
                        value={form.invitationCode}
                        // onChange={(e) => { setForm({ ...form, [e.target.name]: e.target.value }) }}
                    />
                </div>
                <div className="mx-4">
                    <button type="button" onClick={handleUpdateCode}>
                        <FontAwesomeIcon icon={faArrowsRotate} className="text-lg"></FontAwesomeIcon>
                    </button>
                </div>
            </div>
            <TextField
                margin="dense"
                id="code"
                label={t("描述")}
                type="text"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                name="description"
                defaultValue={form.description}
                onChange={(e) => { setForm({ ...form, [e.target.name]: e.target.value }) }}
            />
        </Fragment>
    )
}

function UserForm({data, setUserForm}) {
    const [t, i18n] = useTranslation()
    const [form, setForm] = useState(data)
    const [roleList, setRoleList] = useState([])

    const getRoles = async () => {
        const res = await getAllOrgRoles()
        setRoleList(res.data || [])
    }

    useEffect(()=>{
        getRoles()
    },[])

    useEffect(()=>{
        setUserForm({...form})
    }, [form])

    return (
        <Fragment>
            <TextField
                margin="dense"
                id="code"
                label={t("使用者名稱")}
                type="text"
                fullWidth
                disabled
                variant="outlined"
                name="name"
                value={form.username}
                onChange={(e) => { setForm({ ...form, [e.target.name]: e.target.value }) }}
            />
            <div className="mt-4">
            <span className="mr-4">組織內角色:</span>
                <Select
                    labelId="organizationRoleId-select-label"
                    id="organizationRoleId-select"
                    label="organizationRoleId"
                    name="organizationRoleId"
                    size="small"
                    className="mr-2"
                    defaultValue={"None"}
                    value={form.organizationRoleId}
                    onChange={(e) => { setForm({ ...form, [e.target.name]: e.target.value }) }}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        }
                    }}
                >
                    {
                        roleList.length > 0 ? roleList?.map(v => (
                            v && <MenuItem
                                key={v.id}
                                value={v.id}
                            >
                                {t(v.name)}
                            </MenuItem>
                        )): 
                        <MenuItem
                            value={"None"}
                        >
                            {t("None")}
                        </MenuItem>
                    }
                </Select>
            </div>
        </Fragment>
    )

}

function UserPage() {
    const [userData, setUserData] = useState([])
    const [t, i18n] = useTranslation()
    const organizationInfo = useContext(OrganizationContext)
    const [orgForm, setOrgForm] = useState({})
    const [userForm, setUserForm] = useState({})

    const makeUserData = (rawData) => {
        const data = {
            ...rawData,
            ...rawData.user,
            roleName: rawData.organizationRole.name,
        }

        delete data.organizationRole
        delete data.user

        return data
    }

    const getUsers = async (organizationId) => {
        const resp = await getOrgUsers(organizationId)

        setUserData(resp?.data?.map?.(v => makeUserData(v)) || [])
    }
    
    const getOrgInfo = async (organizationId) => {
        const data = await getOrg(organizationId)

        setOrgForm(data.data || {})
    }

    const onSubmitOrgForm = async () => {
        const resp = await updateOrg(organizationInfo.organizationId, orgForm)

        await getOrgInfo(organizationInfo.organizationId)
    }

    const onSubmitUserForm = async () => {
        await updateUserRole(userForm.organizationId, userForm.userId, userForm.organizationRoleId)

        getUsers(organizationInfo.organizationId)
    }

    const onDeleteOrgUser = (organizationId, userId) => async () => {
        await deleteOrgUser(organizationId, userId)
        
        getUsers(organizationInfo.organizationId)
    }
    
    useEffect(() => {
        organizationInfo.organizationId && getOrgInfo(organizationInfo.organizationId)
    }, [organizationInfo.organizationId])


    useEffect(() => {
        organizationInfo.organizationId && getUsers(organizationInfo.organizationId)
    }, [organizationInfo.organizationId])

    return <div className="pr-10 m-2">
        <div className="flex flex-wrap my-3">
            <FormDialog
                title={t("組織資訊")}
                forms={<OrganizationForm orgId={organizationInfo.organizationId} form={orgForm} setForm={setOrgForm}></OrganizationForm>}
                closeText={t("返回")}
                submitText={t("儲存")}
                onSubmit={onSubmitOrgForm}
                className="text-blue-600 font-semibold text-sm px-2 py-2 hover:bg-gray-100"
            >
                <FontAwesomeIcon icon={faCircleInfo} className="mx-2"></FontAwesomeIcon>
                {t("組織資訊")}
            </FormDialog>
        </div>
        <TableContainer component={Paper}>
            <Table
                sx={{ minWidth: 280 }}
                aria-label="simple table" >
                <TableHead>
                    <TableRow>
                        <TableCell>{t("使用者名稱")}</TableCell>
                        <TableCell>{t("姓名")}</TableCell>
                        <TableCell>{t("角色")}</TableCell>
                        <TableCell>{t("信箱")}</TableCell>
                        <TableCell>{t("檢視與修改")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userData?.map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.username}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {`${row?.lastName} ${row?.firstName}`}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.roleName}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.email}
                            </TableCell>
                            <TableCell>
                                <FormDialog
                                    title={t("檢視與修改")}
                                    forms={<UserForm data={row} setUserForm={setUserForm}></UserForm>}
                                    closeText={t("返回")}
                                    submitText={t("儲存")}
                                    {...(organizationInfo.role === "admin" ? {onSubmit:onSubmitUserForm} : {})}
                                    deleteText={t("刪除")}
                                    {...(organizationInfo.role === "admin" ? {onDelete:onDeleteOrgUser(organizationInfo.organizationId, row.id)} : {})}
                                    className="bg-blue-500 text-white rounded-xl hover:bg-blue-700 px-2 py-1"
                                >
                                    {t("檢視與修改")}
                                </FormDialog>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table >
        </TableContainer >
    </div>
}

export default function UsermIndex({ nav }) {

    return <OrganizationLayout nav={nav} directory={"您的組織成員"}>
        <UserPage></UserPage>
    </OrganizationLayout>
}