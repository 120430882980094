import { createStore, compose } from 'redux'

const initialState = {
    token: null,
    user: null,
    userId: null,
    email: null,
    role: null,
}

export const SET_TOKEN = "SET_TOKEN"
export const SET_USER = "SET_USER"
export const SET_EMAIL = "SET_EMAIL"
export const SET_ROLE = "SET_ROLE"
export const SET_USER_ID = "SET_USER_ID"
export const LOGOUT = "LOGOUT"

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_ID:
            localStorage.setItem("user_id", action.payload)
            return {
                ...state,
                userId: action.payload
            }
        case SET_EMAIL:
            localStorage.setItem("email", action.payload)
            return {
                ...state,
                email: action.payload
            }
        case SET_TOKEN:
            localStorage.setItem("token", action.payload)
            localStorage.setItem("id-token", action.payload)
            return {
                ...state,
                token: action.payload
            }
        case SET_ROLE:
            localStorage.setItem("role", action.payload)
            return {
                ...state,
                role: action.payload
            }
        case SET_USER:
            localStorage.setItem("user", action.payload)
            return {
                ...state,
                user: action.payload
            }
        case LOGOUT:
            localStorage.removeItem("user")
            localStorage.removeItem("token")
            localStorage.removeItem("id-token")
            localStorage.removeItem("user_id")
            localStorage.removeItem("role")
            localStorage.removeItem("email")
            return {
                initialState
            }
        default:
            return state
    }
}

let preloadedState

const persistedToken = localStorage.getItem("token")
const persistedUser = localStorage.getItem("user")
const persistedUserId = localStorage.getItem("user_id")
const persistedEmail = localStorage.getItem("email")
const persistedRole = localStorage.getItem("role")

if (persistedToken && persistedUser) {
    preloadedState = {
        token: persistedToken,
        user: persistedUser,
        userId: persistedUserId,
        email: persistedEmail,
        role: persistedRole,
    }
}

const store = createStore(
    rootReducer,
    preloadedState,
    compose(
        window.__REDUX_DEVTOOLS_EXTENSION__
            ? window.__REDUX_DEVTOOLS_EXTENSION__()
            : (f) => f
    )
)

export default store