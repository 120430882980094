import req from "./api";

export const getUserOrgs = (userId) => {
    return req("get", `/api/v1/user/${userId}/organization`)
}

export const getUser = (userId) => {
    return req("get", `/api/v1/user/${userId}`)
}

export const updateUser = (userId, data) => {
    return req("put", `/api/v1/user/${userId}`, data)
}

export const userJoinOrg = (userId, data) => {
    return req("post", `/api/v1/user/${userId}/join/organization`, data)
}

export const getUserOwnRole = (userId, organizationId) => {
    return req("get", `/api/v1/user/${userId}/role/organization/${organizationId}`)
}

export const userCreateOrg = (userId, data) => {
    return req("post", `/api/v1/user/${userId}/organization`, data)
}

export const userLeaveOrg = (userId, organizationId) => {
    return req("delete", `/api/v1/user/${userId}/organization/${organizationId}`)
}

// router.Get("user/:userId", MakeGetUser(s))
// router.Put("user/:userId", MakeUpdateUser(s))

// router.Get("user/:userId/organization", MakeGetAllOrganizationHandler(s))
// router.Post("user/:userId/join/organization", MakeJoinOrganizationHandler(s))
// router.Post("user/:userId/organization", MakeUserCreateOrgHandler(s))

// router.Delete("user/:userId/organization/:organizationId", MakeUserLeaveOrg(s))