import req from "./api";

export const getLatestWeighingData = (organizationId, limit) => {
    if (!limit || limit <= 0) limit=1
    return req("get", `/api/v1/organization/${organizationId}/report/device/data/latest?limit=${limit}`)
}

export const getAllDevice = (organizationId, deviceType) => {
    return req("get", `/api/v1/organization/${organizationId}/device?device_type=${deviceType}`)
}

export const getDevice = (organizationId, deviceId) => {
    return req("get", `/api/v1/organization/${organizationId}/device/${deviceId}`)
}

export const getDeviceData = (organizationId, deviceId, st, et) => {
    return req("get", `/api/v1/organization/${organizationId}/device/${deviceId}/data?st=${st}&et=${et}`)
}