import { TitleWrapper, ThirdPartyAuth, RememberOrForgot } from '../../components/auth/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailBulk, faKey } from '@fortawesome/free-solid-svg-icons';
import AuthForm from "../../components/auth/form";
import TopNav from "../../components/layout/topNav";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux"
import { SET_TOKEN, SET_USER, SET_USER_ID } from "../../store"
import { userLogIn } from '../../api/auth/auth';
import { useState } from 'react';
import WithNav from '../layout/withNav';
import { useTranslation } from 'react-i18next';
import jwt_decode from "jwt-decode";
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Alert } from '@mui/material';

export default function Login({ nav, redirect = "/" }) {
    let history = useNavigate();
    const dispatch = useDispatch()
    const [t, i18n] = useTranslation()
    const [errorMessage, setErrorMessage] = useState("")

    const [loginPayload, setLoginPayload] = useState({
        username: "",
        password: "",
    });

    function changeLoginPayload(event) {
        setLoginPayload(currentLoginPayload => ({ ...currentLoginPayload, [event.target.name]: event.target.value }))
    }

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const resp = await userLogIn(
                loginPayload
            )

            dispatch({
                type: SET_TOKEN,
                payload: resp.data.token
            })

            dispatch({
                type: SET_USER,
                payload: resp.data.user
            })

            const decoded = jwt_decode(resp.data.token)
            console.log(decoded)

            dispatch({
                type: SET_USER_ID,
                payload: decoded.uid
            })

            history(redirect)
        } catch (error) {
            let exist = false
            if (error.response?.data?.error?.includes("no rows in result")) exist = false

            if (!exist) setErrorMessage(`wrong username or password`)
        }
    }
    return (
        <WithNav className={"h-screen"} nav={nav}>
            <div className="flex h-full w-full justify-center content-center items-center mx-auto fade-out">
                <AuthForm
                    onSubmit={handleSubmit}
                    title={t("登入")}
                    subTitle={t("讓我們建立更智慧的生活")}
                    btnText={t("登入")}
                    height="400px"
                >
                    <div className="flex content-center items-center">
                        <FontAwesomeIcon style={{ color: "#3B5998" }} className="" icon={faUser} color="" />
                        <label htmlFor="username" className="sr-only">
                            {t('帳號')}
                        </label>
                        <input
                            id="username"
                            name="username"
                            type="username"
                            autoComplete="username"
                            required
                            className="relative block w-full px-3 py-2 focus:z-10 sm:text-sm"
                            placeholder={t('帳號')}
                            onChange={changeLoginPayload}
                        />
                    </div>
                    <div className="flex content-center items-center">
                        <FontAwesomeIcon style={{ color: "#3B5998" }} className="" icon={faKey} color="" />
                        <label htmlFor="password" className="sr-only">
                            {t('密碼')}
                        </label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="password"
                            required
                            className="relative block w-full px-3 py-2 focus:z-10 sm:text-sm"
                            placeholder={t('密碼')}
                            onChange={changeLoginPayload}
                        />
                    </div>

                    <div className='py-4'>
                        {errorMessage.length > 0 ? <Alert severity="error">{t(errorMessage)}</Alert> : ""}
                    </div>
                    {/* <RememberOrForgot /> */}
                </AuthForm>
            </div>
        </WithNav>
    )
}