import WithNav from "../layout/withNav";

export default function OrganizationIndex({ nav }) {
    return (
        <WithNav nav={nav}>
            <div>
                <h2>Overview</h2>
            </div>
        </WithNav>
    )
}