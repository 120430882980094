import React from 'react';
import { Navigate } from "react-router-dom";
import { LOGOUT } from "../../store"
import { useDispatch } from "react-redux"
import { useEffect } from 'react';


const Logout = (redirect = "/") => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: LOGOUT })
    }, [])

    return (
        <Navigate to={redirect}></Navigate>
    )
}

export default Logout;