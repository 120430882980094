import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState, useEffect, Fragment, useContext, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faArrowAltCircleRight, faAdd, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import FormDialog from "../../components/basic/formDialog";
import WithNav from "../layout/withNav";
import { Link, useParams } from "react-router-dom";
import { getUserOrgs } from "../../api/user/user";
import { DataObjectSharp } from "@mui/icons-material";
import jwtDecode from "jwt-decode";
import OrganizationLayout, { OrganizationContext } from "./layout";
import { getOrgUsers } from "../../api/user/org";
import { createOrgCredential, deleteOrgCredential, getOrgCredentials, updateOrgCredential } from "../../api/apim/apim";

const ApiForm = ({ form = {}, setForm }) => {
    const [t, i18n] = useTranslation()
    const organizationInfo = useContext(OrganizationContext)

    return (
        <Fragment>
            <div className="mb-2 text-lg font-semibold">
                <span>
                    {t("Credential Info")}
                </span>
            </div>
            <TextField
                margin="dense"
                id="code"
                label={t("Credential Name")}
                type="text"
                fullWidth
                variant="outlined"
                name="displayName"
                defaultValue={form?.displayName}
                onChange={(e) => { setForm({ ...form, [e.target.name]: e.target.value }) }}
            />
            <TextField
                margin="dense"
                id="code"
                label={t("Credential Type")}
                type="text"
                fullWidth
                disabled
                variant="outlined"
                name="invitationCode"
                defaultValue={form?.credentialType?.displayName}
            />
            <TextField
                margin="dense"
                id="code"
                label={t("Endpoint")}
                type="text"
                fullWidth
                disabled
                variant="outlined"
                name="invitationCode"
                defaultValue={`https://api.sq.tornadoedge.app/api/v1/apim/organization/${organizationInfo.organizationIdentity}`}
            />
            <TextField
                margin="dense"
                id="code"
                label={t("Identity")}
                type="text"
                fullWidth
                disabled
                variant="outlined"
                name="invitationCode"
                defaultValue={form?.identity}
            />
            <TextField
                margin="dense"
                id="code"
                label={t("Secret Key")}
                type="text"
                fullWidth
                disabled
                variant="outlined"
                name="invitationCode"
                defaultValue={form?.secretKey}
            />
        </Fragment>
    )
}

export default function APIContent() {
    const [apiData, setApiData] = useState([])
    const [t, i18n] = useTranslation()
    const organizationInfo = useContext(OrganizationContext)
    const [apiForm, setApiForm] = useState({})

    const getCreds = async (organizationId) => {
        const res = await getOrgCredentials(organizationId)
        setApiData(res.data || [])
    }

    const onSubmitCreateApi = async ()=> {
        await createOrgCredential(organizationInfo.organizationId, apiForm.displayName)

        getCreds(organizationInfo.organizationId)
    }

    const onSubmitApiForm = async () => {
        await updateOrgCredential(organizationInfo.organizationId, apiForm.id, apiForm.displayName)

        // console.log(apiForm)

        getCreds(organizationInfo.organizationId)
    }

    const onDeleteApiForm = (organizationId, credId) => async () => {
        await deleteOrgCredential(organizationId, credId)

        getCreds(organizationInfo.organizationId)
    }

    useEffect(() => {
        organizationInfo.organizationId && getCreds(organizationInfo.organizationId)
    }, [organizationInfo.organizationId])

    return <div className="pr-10 m-2">
        {organizationInfo.role === "admin" ? <FormDialog
            title={t("新增API")}
            forms={<ApiForm form={{}} setForm={setApiForm} />}
            closeText={t("返回")}
            submitText={t("新增")}
            className="text-blue-600 font-semibold text-sm px-2 py-2 hover:bg-gray-100"
            onSubmit={onSubmitCreateApi}
        >
            <FontAwesomeIcon icon={faAdd} className="mx-2"></FontAwesomeIcon>
            {t("新增API")}
        </FormDialog> : <></>}
        <TableContainer component={Paper}>
            <Table
                sx={{ minWidth: 280 }}
                aria-label="simple table" >
                <TableHead>
                    <TableRow>
                        <TableCell>{t("Credential Name")}</TableCell>
                        <TableCell>{t("Credential Type")}</TableCell>
                        <TableCell>{t("Endpoint")}</TableCell>
                        <TableCell>{t("檢視與修改")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {apiData?.map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.credential.displayName}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.credential.credentialType.displayName}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {`https://api.sq.tornadoedge.app/api/v1/apim/organization/${organizationInfo.organizationIdentity}`}
                            </TableCell>
                            <TableCell>
                                <FormDialog
                                    title={t("檢視與修改")}
                                    forms={<ApiForm form={row.credential} setForm={setApiForm}></ApiForm>}
                                    closeText={t("返回")}
                                    submitText={t("儲存")}
                                    {...(organizationInfo.role === "admin" ? { onSubmit: onSubmitApiForm } : {})}
                                    deleteText={t("刪除")}
                                    {...(organizationInfo.role === "admin" ? { onDelete: onDeleteApiForm(row.organizationId, row.credentialId) } : {})}
                                    className="bg-blue-500 text-white rounded-xl hover:bg-blue-700 px-2 py-1"
                                >
                                    {t("檢視與修改")}
                                </FormDialog>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table >
        </TableContainer >
    </div>
}
