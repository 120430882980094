import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState, useEffect, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faArrowAltCircleRight, faAdd } from "@fortawesome/free-solid-svg-icons";
import FormDialog from "../../components/basic/formDialog";
import WithNav from "../layout/withNav";
import { Link, useParams } from "react-router-dom";
import { getUserOrgs, userCreateOrg, userJoinOrg } from "../../api/user/user";
import { DataObjectSharp } from "@mui/icons-material";
import jwtDecode from "jwt-decode";
import { UserGroup } from "./userGroup";

const JoinOrganizationForm = ({ form = {}, setForm }) => {
    const [t, i18n] = useTranslation()

    return (
        <Fragment>
            <div className="mb-2">
                <span >
                    {t("在下方填入組織邀請碼，以加入組織。")}
                </span>
            </div>
            <TextField
                margin="dense"
                id="code"
                label={t("邀請碼")}
                type="text"
                fullWidth
                variant="outlined"
                name="invitationCode"
                onChange={(e) => { setForm({ ...form, [e.target.name]: e.target.value }) }}
            />
        </Fragment>
    )
}

const CreateOrganizationForm = ({ form = {}, setForm }) => {
    const [t, i18n] = useTranslation()

    return (
        <Fragment>
            <div className="mb-2">
                <span >
                    {t("在下方填入組織資訊，以建立組織。")}
                </span>
            </div>
            <TextField
                margin="dense"
                id="code"
                label={t("組織名稱")}
                type="text"
                fullWidth
                variant="outlined"
                name="name"
                onChange={(e) => { setForm({ ...form, [e.target.name]: e.target.value }) }}
            />
            <TextField
                margin="dense"
                id="code"
                label={t("描述")}
                type="text"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                name="description"
                onChange={(e) => { setForm({ ...form, [e.target.name]: e.target.value }) }}
            />
        </Fragment>
    )
}

export default function UserIndex({ nav }) {
    const [t, i18n] = useTranslation()
    const [organizationData, setOrganizationData] = useState([])
    const [createOrgForm, setCreateOrgForm] = useState({})
    const [userJoinOrgForm, setUserJoinOrgForm] = useState({})
    const jwt = jwtDecode(localStorage.getItem("token"))
    const agreement = jwt.agreement
    const userId = jwt.uid

    function makeOrgData(obj) {
        const temp = {}
        obj.forEach(e => {
            if (!e?.organization) return

            temp[e.organization.id] = e.organization
        })

        const data = []
        Object.entries(temp).forEach(([k, v], i) => {
            data.push(v)
        })


        return data
    }

    async function getOrgs(userId) {
        const data = await getUserOrgs(userId)
        setOrganizationData(makeOrgData(data?.data) || [])
        // console.log(makeOrgData(data?.data))
    }

    async function onSubmitCreateOrg() {
        const resp = await userCreateOrg(userId, createOrgForm)
        await getOrgs(userId)
    }

    async function onSubmitUserJoinOrg() {
        const resp = await userJoinOrg(userId, userJoinOrgForm)
        await getOrgs(userId)
    }

    useEffect(() => {
        getOrgs(userId)
    }, [userId])

    useEffect(() => {
        // console.log(createOrgForm)
    }, [createOrgForm])

    return (
        <WithNav nav={nav}>
            <div className="mt-12 border-b-2 mx-auto max-w-6xl w-5/6">
                <div className="text-2xl font-semibold">
                    您的組織
                </div>
                <div className="flex flex-wrap my-3">
                    {agreement === "superuser" ? <FormDialog
                        title={t("建立組織")}
                        forms={<CreateOrganizationForm form={createOrgForm} setForm={setCreateOrgForm} />}
                        closeText={t("返回")}
                        submitText={t("建立")}
                        className="text-blue-600 font-semibold text-sm px-2 py-2 hover:bg-gray-100"
                        onSubmit={onSubmitCreateOrg}
                    >
                        <FontAwesomeIcon icon={faAdd} className="mx-2"></FontAwesomeIcon>
                        {t("建立組織")}
                    </FormDialog> : <></>}
                    <FormDialog
                        title={t("加入組織")}
                        forms={<JoinOrganizationForm form={userJoinOrgForm} setForm={setUserJoinOrgForm} />}
                        closeText={t("返回")}
                        submitText={t("加入")}
                        className="text-blue-600 font-semibold text-sm px-2 py-2 hover:bg-gray-100"
                        onSubmit={onSubmitUserJoinOrg}
                    >
                        <FontAwesomeIcon icon={faArrowAltCircleRight} className="mx-2"></FontAwesomeIcon>
                        {t("加入組織")}
                    </FormDialog>
                </div>
                <TableContainer component={Paper}>
                    < Table
                        sx={{ minWidth: 280 }}
                        aria-label="simple table" >
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("組織名稱")}</TableCell>
                                <TableCell>{t("組織成員")}</TableCell>
                                <TableCell>{t("邀請碼")}</TableCell>
                                <TableCell>{t("組織描述")}</TableCell>
                                <TableCell>{t("前往組織")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {organizationData?.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <UserGroup userData={row.members.map(v => v?.username)}></UserGroup>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {t(row.invitationCode)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {t(row?.description)}
                                    </TableCell>
                                    <TableCell>
                                        <Link to={`/organization/${row.name}-${row.id.toString()}/management/user`} className="px-2 py-1 rounded-lg bg-blue-500 text-white hover:bg-blue-700">Go</Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table >
                </TableContainer >
            </div>
        </WithNav >
    )
}