import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState, useEffect, Fragment, useContext, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faArrowAltCircleRight, faAdd, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import FormDialog from "../../components/basic/formDialog";
import WithNav from "../layout/withNav";
import { Link, useParams } from "react-router-dom";
import { getUserOrgs } from "../../api/user/user";
import { DataObjectSharp } from "@mui/icons-material";
import jwtDecode from "jwt-decode";
import OrganizationLayout, { OrganizationContext } from "./layout";
import { getOrgUsers } from "../../api/user/org";

const OrganizationForm = ({ form = {}, setForm }) => {
    const [t, i18n] = useTranslation()

    return (
        <Fragment>
            <div className="mb-2">
                <span >
                    {t("在下方填入組織資訊，以建立組織。")}
                </span>
            </div>
            <TextField
                margin="dense"
                id="code"
                label={t("組織名稱")}
                type="text"
                fullWidth
                variant="outlined"
                name="name"
                onChange={(e) => { setForm({ ...form, [e.target.name]: e.target.value }) }}
            />
            <TextField
                margin="dense"
                id="code"
                label={t("邀請碼")}
                type="text"
                fullWidth
                variant="outlined"
                name="invitationCode"
                onChange={(e) => { setForm({ ...form, [e.target.name]: e.target.value }) }}
            />
            <TextField
                margin="dense"
                id="code"
                label={t("描述")}
                type="text"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                name="description"
                onChange={(e) => { setForm({ ...form, [e.target.name]: e.target.value }) }}
            />
        </Fragment>
    )
}

export default function LocationContent() {
    const [userData, setUserData] = useState([])
    const [t, i18n] = useTranslation()
    const organizationInfo = useContext(OrganizationContext)

    const makeUserData = (rawData) => {
        const data = {
            ...rawData,
            ...rawData.user,
            roleName: rawData.organizationRole.name,
        }

        delete data.organizationRole
        delete data.user

        return data
    }

    const getUsers = async (organizationId) => {
        const resp = await getOrgUsers(organizationId)

        setUserData(resp?.data?.map?.(v => makeUserData(v)) || [])
    }

    useEffect(() => {
        getUsers(organizationInfo.organizationId)
    }, [organizationInfo.organizationId])

    return <div className="pr-10">
        <div className="flex flex-wrap my-3">
            <FormDialog
                title={t("組織資訊")}
                forms={<></>}
                closeText={t("返回")}
                submitText={t("儲存")}
                className="text-blue-600 font-semibold text-sm px-2 py-2 hover:bg-gray-100"
            // onSubmit={onSubmitJoin}
            >
                <FontAwesomeIcon icon={faCircleInfo} className="mx-2"></FontAwesomeIcon>
                {t("組織資訊")}
            </FormDialog>
        </div>
        <TableContainer component={Paper}>
            <Table
                sx={{ minWidth: 280 }}
                aria-label="simple table" >
                <TableHead>
                    <TableRow>
                        <TableCell>{t("使用者名稱")}</TableCell>
                        <TableCell>{t("姓名")}</TableCell>
                        <TableCell>{t("角色")}</TableCell>
                        <TableCell>{t("信箱")}</TableCell>
                        <TableCell>{t("檢視與修改")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userData?.map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.username}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {`${row?.lastName} ${row?.firstName}`}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.roleName}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.email}
                            </TableCell>
                            <TableCell>
                                <Link
                                    to={`/organization/${row.name}-${row.id.toString()}/management/user`}
                                    className="px-2 py-1 rounded-lg bg-blue-500 text-white hover:bg-blue-700">
                                    {t("檢視與修改")}
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table >
        </TableContainer >
    </div>
}
