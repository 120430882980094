import "./topNav.css";
import SideNav from "./sideNav";

export default function TopNav({ className = "", logo, children, sideChildren }) {
    return (
        <nav className={`top-nav ${className}`}>
            <div className="flex justify-between items-center mx-6 text-center content-center min-h-[2.5rem]">
                <ul className="flex items-center content-center justify-between">
                    {sideChildren && <li><SideNav className="bg-white" widthClass="w-64">
                        {sideChildren}
                    </SideNav></li>}
                    <li>
                        {logo}
                    </li>
                </ul>
                <ul className="flex space-x-3 items-center text-center content-center align-middle">
                    {children}
                </ul>
            </div>
        </nav>
    )
}