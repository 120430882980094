
import { Logo } from './logo';
import { NavContent } from './navContent';
import { I18nBtn } from './i18nBtn';
import { UserAvatar } from './userAvatar';
import TopNav from './topNav';

export const BasicTopNav = (user, token, routing, userRedirect, homeRedirect, sideChildren, userSettings, className, logo = true, children) => (
    //border-b-2 shadow-sm bg-white
    <TopNav className={className} logo={logo && Logo(homeRedirect)} sideChildren={sideChildren}>
        {children}
        {NavContent(user, token, routing)}
        <I18nBtn></I18nBtn>
        {token && <UserAvatar user={user} userSettings={userSettings}></UserAvatar>}
    </TopNav>
)
