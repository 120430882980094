import { AvatarGroup, Tooltip, Avatar } from "@mui/material"

export const UserGroup = ({ userData = [] }) => {
    return <AvatarGroup max={15} className="justify-end"
        componentsProps={{
            additionalAvatar: {
                sx: { width: 24, height: 24 }
            }
        }}
    >
        {userData.map((value, index) => {
            return value ? <Tooltip
                key={index}
                title={value}
                placement="bottom"
                sx={{ width: 24, height: 24 }}
                arrow
            >
                <Avatar alt={value} src="/static/213.png" />
            </Tooltip> : ""
        })}
    </AvatarGroup>
}